@import 'fonts';
@import 'variables';
@import 'swiper/scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'react-toastify/dist/ReactToastify.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $body-font-family;
  p {
    margin-bottom: 0;
  }
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body.lock-scroll {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}
