@import '@/styles/styles';

.container {
  max-width: 100vw;
  overflow: hidden;
}

.content {
  @include layout();
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}
