@import '@/styles/styles';

.container {
  margin-top: auto;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 70px;
  padding-top: 40px;
  position: relative;
  width: 100%;

  @include media-breakpoint-down(md) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.background {
  @include fullWidth();
  background-color: rgba($color: $primary, $alpha: 0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}

.info {
  .logo {
    color: $primary;
    font-size: 40px;
    font-style: italic;
    font-weight: 900;
    line-height: 51px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .navItems {
    column-gap: 30px;
    display: grid;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    grid-template-columns: repeat(5, auto);
    line-height: 20px;
    row-gap: 30px;
  }

  @include media-breakpoint-down(lg) {
    .navItems {
      grid-template-columns: repeat(3, auto);
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 45px;
    .navItems {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.locale {
  column-gap: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .item {
    .label {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 8px;
    }
  }
}
