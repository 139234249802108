@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-Book.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 450;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-BookItalic.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 500;
  src: local('Circular Std'),
    url('/fonts/circular-std/CircularStd-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 700;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-Black.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 900;
  src: local('Circular Std'), url('/fonts/circular-std/CircularStd-BlackItalic.woff') format('woff');
}
