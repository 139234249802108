@import 'variables';
@import 'breakpoint';

@mixin fullWidth {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@mixin linesLimit($maxLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxLine;
  line-clamp: $maxLine;
  -webkit-box-orient: vertical;
}

@mixin layout() {
  max-width: 1440px;
  padding-left: 135px;
  padding-right: 135px;
  width: 100%;
  margin: 0 auto;

  @include media-breakpoint-down(xl) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@mixin cardCover($padding: 0) {
  background: #ffffff;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  padding: $padding;
}
@mixin container() {
  background: #ffffff;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  padding: 40px;
}

@mixin btnHover($color, $background) {
  &:hover {
    color: $color;
    background: $background;
    transition: all 0.3s ease-in-out;
    svg,
    path {
      transition: fill 0.3s ease-in-out;
      fill: $color;
    }
  }
}

@mixin infoText() {
  color: #00000080;
  font-size: 12px;
  line-height: 15px;
}
@mixin textBold() {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
