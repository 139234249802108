@import '@/styles/styles';

.container {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  position: absolute;
  top: 0;
  transition: background 0.3s ease;
  width: 100%;
  z-index: 999;
  @include layout();

  .background {
    border-bottom: 1px solid #ffffff20;
    height: 100%;
    left: 0;
    margin-left: calc(50% - 50vw);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  .logo {
    font-size: 30px;
    font-style: italic;
    font-weight: 900;
    line-height: 38px;
    margin-right: 20px;
    text-transform: uppercase;
    z-index: 1;
  }

  .search {
    flex-shrink: 0;
    margin-right: 29px;
    width: 270px;

    :global {
      .input-group {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        height: 35px;

        .input-group-text {
          background-color: transparent;
          border: none;
        }

        input.form-control {
          background-color: transparent;
          border: none;
          color: #ffffff;
          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            color: #ffffff;
            opacity: 1; /* Firefox */
          }
        }
      }
    }
  }

  .menuItems {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  .links {
    column-gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: auto;
    margin-right: 20px;
    padding: 5px 0;
    white-space: nowrap;
    @include linesLimit(1);
  }

  .linkItem {
    color: inherit;
    cursor: pointer;
    position: relative;
    text-decoration: none;

    &::after {
      background-color: $primary;
      border-radius: 2px;
      bottom: -4px;
      content: '';
      height: 2px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      transition: width 0.3s ease;
      width: 0;
    }

    &:hover {
      &::after {
        width: 80%;
      }
    }
  }

  .auth {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, auto);

    button {
      color: #ffffff;
      min-width: 100px;
      --bs-btn-padding-x: 1.25rem;
      --bs-btn-padding-y: 0.4378rem;
    }
  }

  .hamburgerLines {
    align-items: flex-end;
    cursor: pointer;
    display: none;
    flex-direction: column;
    height: 18px;
    justify-content: space-between;
    width: 32px;
    z-index: 1;

    .line {
      background: #ffffff;
      border-radius: 10px;
      display: block;
      height: 2px;
      width: 100%;

      &.line1 {
        transform-origin: center left;
        transition: transform 0.4s ease-in-out, width 0.3s ease, background 0.3s ease;
      }

      &.line2 {
        transition: transform 0.2s ease-in-out, background 0.3s ease;
      }

      &.line3 {
        transform-origin: center left;
        transition: transform 0.4s ease-in-out, width 0.3s ease, background 0.3s ease;
      }
    }

    &.active {
      .line {
        &.line1 {
          transform: rotate(45deg);
          width: 22px;
        }
        &.line2 {
          transform: scaleY(0);
        }
        &.line3 {
          transform: rotate(-45deg);
          width: 22px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &.active {
      background-color: #000000;
    }

    .search {
      flex-shrink: 1;
      margin-right: 20px;
      z-index: 1;
      margin-bottom: auto;
      width: 100%;
      margin-right: 0;
    }

    .menuItems {
      background-color: #000000;
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 78px);
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: -100vh;
      transition: top 0.5s;
      width: 100%;
      z-index: 0;
      padding: 32px;

      &.active {
        top: 78px;
      }
    }

    .links,
    .auth {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0;
      overflow: visible;
      row-gap: 20px;
      margin-bottom: auto;
    }

    .links {
      margin-bottom: 20px;
    }

    .hamburgerLines {
      display: flex;
      margin-left: auto;
      z-index: 1;
    }
  }
}
.userName {
  white-space: nowrap;
  cursor: pointer;
  color: #49a5ef;
}
